import { call, put, takeLatest } from 'redux-saga/effects';
import {
	FETCH_LOAD_SKETCH,
	FETCH_LOAD_SKETCH_SUCCESS,
	FETCH_LOAD_SKETCH_FAILED,
} from 'redux/actions/ActionTypes';
import UserAPI from 'utils/UserAPI';
import { LOAD_SKETCH } from 'const/Api';

function* fetchLoadSketch(action) {
	try {
		const { id } = action.params;
		const url = `${LOAD_SKETCH}?id=${id}`;
		const result = yield call(UserAPI.getFetch, url, action.token);
		if (result.success) {
			yield put({ type: FETCH_LOAD_SKETCH_SUCCESS, data: result });
			action.resolve(result);
		} else {
			yield put({
				type: FETCH_LOAD_SKETCH_FAILED,
				error: result.message,
			});
			action.reject(result.message);
		}
	} catch (error) {
		yield put({ type: FETCH_LOAD_SKETCH_FAILED, error: error });
		action.reject(error);
	}
}

export function* watchFetchLoadSketch() {
	yield takeLatest(FETCH_LOAD_SKETCH, fetchLoadSketch);
}
