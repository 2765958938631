import {
	FETCH_LOAD_SKETCH,
	FETCH_LOAD_SKETCH_SUCCESS,
	FETCH_LOAD_SKETCH_FAILED,
} from 'redux/actions/ActionTypes';

const INIT_STATE = {
	data: null,
	error: null,
	fetching: false,
};

const LoadSketchReducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_LOAD_SKETCH:
			return {
				...state,
				error: null,
				fetching: true,
			};
		case FETCH_LOAD_SKETCH_SUCCESS:
			return {
				data: action.data,
				error: null,
				fetching: false,
			};
		case FETCH_LOAD_SKETCH_FAILED:
			return {
				...state,
				error: action.error,
				fetching: false,
			};
		default:
			return state;
	}
};

export default LoadSketchReducer;
