import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routers from 'routers';

const App = props => {
	// store
	const loading = useSelector(store => store.loading);

	useEffect(() => {
		const loadingStatus = JSON.stringify({
			type: 'loading',
			loading: loading,
		});
		if (typeof window.ReactNativeWebView !== 'undefined') {
			window.ReactNativeWebView.postMessage(loadingStatus);
		}
	}, [loading]);

	return (
		<Router basename='/'>
			<Suspense fallback={<div></div>}>
				{routers.map((route, index) => (
					<Route key={route.id} {...route} />
				))}
			</Suspense>
		</Router>
	);
};

export default App;
