import { TOGGLE_LOADING } from 'redux/actions/ActionTypes';

const INIT_STATE = false;

const LoadingReducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case TOGGLE_LOADING:
			return action.value;
		default:
			return state;
	}
};

export default LoadingReducer;
