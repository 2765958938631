import { API_ENDPOINT, API_VERSION } from 'config/environments';

const BASE_URL = API_ENDPOINT.concat(API_VERSION);

const UserAPI = {
	getFetch: (url, token, headers) => {
		return fetch(BASE_URL.concat(url), {
			method: 'GET',
			'cache-control': 'no-cache',
			headers: {
				token: token,
				...headers,
			},
		})
			.then(response => {
				return response.json();
			})
			.catch(error => {
				return error;
			});
	},
};

export default UserAPI;
